
.boton-negro {
  background-color: black;
  color: white;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin: 5px;
}
.boton-negro:hover {
  color: lightgrey;
}
.boton-blanco {
  background-color: white;
  color: black;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  border-style: solid;
  border-color: black;
  margin: 5px;
}
.boton-blanco:hover {
  color: lightgrey;
}
.footer {
  background: #ededed 0% 0% no-repeat padding-box;
  position: relative;
  bottom: 0;
}

.carousel {
  width: 100%;
  height: 360px;
  margin: 0;
  padding: 0;
}
.carousel-inner > .item > img {
  width: 100%;
  height: 360px;
}
.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.boton-negro-login {
  background-color: black;
  color: white;
  height: 40px;
  font-size: 12px;
  border-radius: 0%;
  font-size: 15px !important;
}
.boton-negro-login:hover {
  color: lightgrey;
}
.link-login {
  color: lightslategrey;
  font-size: 15px;
}
.boton-cancelar {
  background-color: lightgrey;
  border-color: lightgrey;
  color: grey;
  height: 40px;
  width: 100px;
}
.boton-cancelar:hover {
  background-color: lightgray;
  color: white !important;
}


